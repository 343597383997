.main-login {
  align-content: center;
  background: #3E73A8;
  display: flex;
  justify-content: center;
  width: 100%;
}


.login-container {
  background: whitesmoke;
  padding: 20px 40px 10px 40px;
  border-radius: 20px;
  box-shadow: 2px 1px 5px 1px rgba(0, 0, 0, 0.3);
  .logo {
    text-align: center;
  }
  .img-logo {
    width: 100%;
    max-width: 250px;
  }
  h2 {
    text-align: center;
    padding-bottom: 15px;
  }
  div {
    padding-bottom: 5px;
    text-align: right;
    input {
      margin-left: 5px;
    }
  }
  .error-text {
    color:red;
    font-style: italic;
  }
  .btn-container {
    display: flex;
    padding-top: 10px;
    width: 100%;
    justify-content: center;
  }
}



